<template>
  <section>
    <v-breadcrumbs>
      <template v-for="(item, index) in breadcrumbs">
        <v-breadcrumbs-item
          :key="index"
          :href="item.href"
          :disabled="item.disabled"
          v-if="item.show"
        >
          {{ item.text }}
        </v-breadcrumbs-item>
        <v-breadcrumbs-divider v-if="index < breadcrumbs.length - 1">
          /
        </v-breadcrumbs-divider>
      </template>
      <v-col cols="12" md="2" class="align-end">
        <v-btn 
            v-if="ShowBotonReporte"
            dark
            block
            color="secondary"
            @click="(page = 1), obtenerReporte()"
          >Reporte</v-btn
        >
      </v-col>
    </v-breadcrumbs>

    <!-- Filtros de busqueda -->
    <v-col cols="12" sm="12" md="10">
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            label="Buscar por: código de proceso, nombre de proceso, nombre institución"
            v-model="filterNombre"
            hide-details="auto"
            outlined
            maxlength="50"
            clearable
            @click:clear="(filterNombre = null), filterName()"
            @keyup="filterName"
          />
        </v-col>

        <v-col cols="12" md="4">
          <v-autocomplete
            v-model="filterResultado"
            :items="ctl_resultados"
            item-text="resultado"
            item-value="resultado"
            label="Resultado del proceso"
            outlined
            hide-details
            clearable
            @change="filterResult"
            @keyup="filterResult"
          /> 
        </v-col>
      </v-row>
    </v-col>

    <data-table-component
      :show_loading="dataLoading"
      :headers="headers"
      :items="procesos"
      v-models:select="porPaginaModel"
      v-models:pagina="paginaModel"
      @paginar="paginar"
      :total_registros="filters.total_rows"
    >
      <template #[`item.monto_ganador`]="{ item }">
        <span>
          {{ toMoneyFormat(item.monto_ganador) }}
        </span>
      </template>
      <template #[`item.ganador`]="{ item }">
        <v-chip color="success" class="white--text" v-if="item.id_ganador"
          >Ganador</v-chip
        >
        <v-chip color="info" class="white--text" v-else>Participante</v-chip>
      </template>
    </data-table-component>
    <v-dialog v-model="modalReporte" max-width="600">
      <v-card class="pa-4">
        <v-card-text>
          <p class="text-center text-h6 secondary--text my-4">
            El reporte iniciará su ejecución en:
          </p>
          <app-countdown 
            v-if="contador"
            color="info--text"
            :date-now="contador"
            :nextDay="finalizacionTimer"
            @status="modalReporte = false"
          />
          <p class="text-center text-h6 secondary--text my-4">
            Se le notificará al correo cuando el reporte haya finalizado. Si
            después de 15 minutos no le ha llegado el correo vuelva a ejecutar
            el proceso.
          </p>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn dark color="secondary" @click="modalReporte = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Botón de regresar -->
    <v-row>
      <v-col cols="12">
        <v-btn class="mt-5" color="primary" outlined @click="regresar">
          Regresar
        </v-btn>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import moment from 'moment'
import DataTableComponent from '../../components/DataTableComponent.vue'
import { toMoneyFormat } from '@/utils/data'

export default {
  name: 'procesosProveedorView',
  components: {
    DataTableComponent
  },
  data: () => ({
    headers: [
      {
        text: 'Código proceso',
        value: 'codigo_proceso',
        sortable: false
      },
      {
        text: 'Nombre Institución',
        value: 'nombre_institucion',
        sortable: false
      },
      {
        text: 'Nombre procesos',
        value: 'nombre_proceso',
        sortable: false
      },
      {
        text: 'Método de proceso',
        value: 'modalidad',
        sortable: false
      },
      {
        text: 'Fecha de publicación',
        value: 'fecha_de_publicacion',
        sortable: false
      },
      {
        text: 'Monto adjudicado ganador',
        value: 'monto_ganador',
        align: "center",
        sortable: false
      },
      {
        text: 'Resultado del proceso',
        value: 'ganador',
        sortable: false
      }
    ],
    procesos: [],
    filters: {
      page: 1,
      per_page: 10,
      total_rows: null,
      pagination: true,
      busqueda: null,
      resultado: null,
    },
    modalReporte: false,
    contador: null,
    finalizacionTimer: null,
    dataLoading: false,
    filterNombre: null,
    filterResultado: null,
    ctl_resultados: [
      {
        id: 1,
        resultado: "Participante",
      },
      {
        id: 2,
        resultado: "Ganador",
      },
    ],
  }),
  computed: {
    ...mapState('proveedores', ['provider']),
    breadcrumbs () {
      return [
        {
          show: !this.haveRole('ROLE_SUPERVISOR'),
          text: 'RUPES',
          disabled: false,
          href: `/rupes`
        },
        {
          show: true,
          text: this.provider?.nombre,
          disabled: false,
          href: `/proveedor/${this.$route.params.id_proveedor}`
        },
        {
          show: true,
          text: 'Procesos',
          disabled: true
        }
      ]
    },
    ShowBotonReporte(){
      return (this.haveRole("ROLE_PARTICIPACION_REPORTE_VIEW"))
    },
    porPaginaModel: {
      get () {
        return this.filters.per_page
      },
      set (value) {
        this.setFiltroPorPagina(value)
      }
    },
    paginaModel: {
      get () {
        return this.filters.page
      },
      set (value) {
        this.setFiltroPage(value)
      }
    }
  },
  methods: {
    ...mapActions('proveedores', ['getProvider']),
    toMoneyFormat,
    async getProcesosProveedor () {
      this.dataLoading = true
      const {headers, status, data} = await this.services.Proveedores.getProcesosProveedor(
        this.provider.id,
        this.filters
      )

      if (status === 200) {
        this.procesos = data
        this.filters.page = Number(headers.page)
        this.filters.per_page = Number(headers.per_page)
        this.filters.total_rows = Number(headers.total_rows)
      }

      setTimeout(() => (this.dataLoading = false), 200)
    },
    paginar (filtros) {
      const { cantidad_por_pagina, pagina } = filtros
      this.filters.page = pagina
      this.filters.per_page = cantidad_por_pagina
      this.getProcesosProveedor()
    },
    regresar () {
      this.$router.push({
        name: 'proveedor',
        params: { id_proveedor: this.$route.params.id_proveedor }
      })
    },
    async obtenerReporte() {
        this.showLoader();
        const {status} = await this.services.Proveedores.generarReporteParticipacion(this.$route.params.id_proveedor);
        if (status == 200) {
          this.modalReporte = true;
        }
        this.hideLoader();
      },
    filterName() {
      if (this.timer) clearTimeout(this.timer);
      this.filters.busqueda =
        this.filterNombre != "" ? this.filterNombre : null;
      this.timer = setTimeout(async () => this.getProcesosProveedor(), 500);
    },
    filterResult() {
      if (this.timer) clearTimeout(this.timer);
      this.filters.resultado =
        this.filterResultado != "" ? this.filterResultado : null;
      this.timer = setTimeout(async () => this.getProcesosProveedor(), 5);
    },
  },
  watch:{
    modalReporte(value){
      if (value) {
        this.contador = moment();
        this.finalizacionTimer = moment().add(5, 'seconds');
      }	else {	
        this.contador = null;
        this.finalizacionTimer = null;
      }
    },
    filterNombre(v) {
      if (v === null) this.getProcesosProveedor();
    },
    filterResultado(v) {
      if (v === null) this.getProcesosProveedor();
    },
  },
  async created () {
    await this.getProvider(Number(this.$route.params.id_proveedor))
    await this.getProcesosProveedor()
  }
}
</script>
